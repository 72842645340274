import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import amber from "@material-ui/core/colors/amber";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: amber[500],
    },
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 0
      }
    },
    MuiAccordionSummary: {
      expandIcon: {
        transform: 'rotate(90deg)'
      }
    }
  },
});
