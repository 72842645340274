import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha"
import { reCaptchaSiteKey } from "../../data/reCaptchaSiteKey";
import { emailRegex } from "../../data/validationRegexes";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import PageHeader from "../PageHeader";
import axios from "axios";
import { sendMailEndpoint } from "../../endpoints/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import { tosPath } from "../../data/paths"

const styles = (theme: Theme) => ({
  container: {
    marginBottom: 24
  },
  description: {
    marginTop: 24
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    marginTop: 24
  },
  checkboxRow: {
    marginTop: 16
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24
  },
  spacer: {
    flexGrow: 1,
  },
  sendButton: {
    height: '36px',
    width: '70px'
  },
  errorMessage: {
    color: 'red',
    marginRight: 24
  },
  sectionHeader: {},
  image: {
    height: 'auto',
    width: '800px',
    maxWidth: '100%',
    border: '2px solid gray'
  }
});

class RequestAccessPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      organization: '',
      city: '',
      message: '',
      tos: false,
      reCaptchaToken: '',
      emailAlertsChecked: false,
      // validation errors
      nameRequiredError: false,
      emailRequiredError: false,
      emailInvalidError: false,
      messageRequiredError: false,
      tosRequiredError: false,
      // state of sending message
      messageSending: false,
      messageSent: false,
      messageSendError: ''
    }
  }

  captcha;

  canSendMessage = () => {
    return !this.state.nameRequiredError
      && !this.state.emailRequiredError
      && !this.state.emailInvalidError
      && !this.state.messageRequiredError
      && !this.state.tosRequiredError
  }

  isValidEmail = (email: string) => {
    return emailRegex.test(email)
  }

  validateName = () => {
    if (!this.state.name) {
      this.setState({ nameRequiredError: true })
    }
  }

  validateEmail = () => {
    if (!this.state.email) {
      this.setState({ emailRequiredError: true })
    } else if (!this.isValidEmail(this.state.email)) {
      this.setState({ emailInvalidError: true })
    }
  }

  validateMessage = () => {
    if (!this.state.message) {
      this.setState({ messageRequiredError: true })
    }
  }

  validateTos = () => {
    if (!this.state.tos) {
      this.setState({ tosRequiredError: true })
    } else {
      this.setState({ tosRequiredError: false })
    }
  }

  /**
   * Record the reCAPTCHA token after the user checks the "I'm not a robot" checkbox.
   */
  onReCaptchaCheck = (reCaptchaToken: string) => {
    this.setState({ reCaptchaToken: reCaptchaToken });
  }

  /**
   * Record changes to name, email, organization, city, message, ToS and email alerts.
   */
  onNameChange = (name: string) => {
    this.setState({
      name: name,
      nameRequiredError: false
    })
  }

  onEmailChange = (email: string) => {
    this.setState({
      email: email,
      emailRequiredError: false,
      emailInvalidError: false
    })
  }

  onOrganizationChange = (organization: string) => {
    this.setState({ organization: organization })
  }

  onCityChange = (city: string) => {
    this.setState({ city: city })
  }

  onMessageChange = (message: string) => {
    this.setState({
      message: message,
      messageRequiredError: false
    })
  }

  onEmailAlertsChange = (checked: boolean) => {
    this.setState({ emailAlertsChecked: checked })
  }
    
  onTosCheck = (event) => {
    this.setState({tos: event.target.checked});
  }

  sendMessage = () => {
    Promise.all([
      this.validateName(),
      this.validateEmail(),
      this.validateMessage(),
      this.validateTos()
    ]).then(() => {
      if (this.canSendMessage()) {
        this.setState({
          messageSending: true,
          messageSent: false,
          messageSendError: ''
        })

        const payload = {
          subject: "request access",
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
          recaptcha_token: this.state.reCaptchaToken,
          organization: this.state.organization,
          city: this.state.city,
          subscribe: this.state.emailAlertsChecked
        }

        axios.post(sendMailEndpoint, payload)
          .then(response => {
            this.setState({
              messageSending: false,
              messageSent: true,
              messageSendError: ''
            })
          })
          .catch(err => {
            this.setState({
              messageSending: false,
              messageSent: false,
              reCaptchaToken: ''
            })
            this.captcha.reset()
            if (err.response) {
              if (err.response.status === 401) {
                this.setState({
                  messageSendError: 'Your message could not be sent. Please try again.'
                })
              } else if (err.response.status === 400) {
                this.setState({
                  messageSendError: err.response.data['error_message'] || 'Bad request.'
                })
              } else if (err.response.status === 500) {
                this.setState({
                  messageSendError: 'Server error. Please contact an administrator.'
                })
              }
            } else if (err.request) {
              this.setState({
                messageSendError: 'Message could not be sent. Please try again.'
              })
            } else {
              this.setState({
                messageSendError: 'Unknown error.'
              })
            }
          })
      }
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <PageHeader displayValue="Request Access"/>
        <Container className={classes.container}>
          {this.state.messageSent
            ?
            <div className={classes.description}>
              <Typography variant="body1">
                Your request has been sent. We will get back to you as soon as we can. Thank you!
              </Typography>
            </div>
            :
            <div>
              <div className={classes.description}>
                <Typography variant="body1">
                  Please fill out the form below to request an API key used for accessing our datasets.
                </Typography>
              </div>
              <form id="access-form" noValidate autoComplete="off" className={classes.form}>
                <TextField
                  className={classes.textField}
                  id="name"
                  label={this.state.nameRequiredError ? "Name \u2014 Required" : "Name"}
                  variant="outlined"
                  autoComplete="off"
                  error={this.state.nameRequiredError}
                  onBlur={this.validateName}
                  onChange={(event) => this.onNameChange(event.target.value)}
                  disabled={this.state.messageSending}
                />
                <TextField
                  className={classes.textField}
                  id="email"
                  label={this.state.emailRequiredError ? "Email \u2014 Required" : this.state.emailInvalidError ? "Email \u2014 Invalid Format" : "Email"}
                  variant="outlined"
                  autoComplete="off"
                  type="email"
                  error={this.state.emailRequiredError || this.state.emailInvalidError}
                  onBlur={this.validateEmail}
                  onChange={(event) => this.onEmailChange(event.target.value)}
                  disabled={this.state.messageSending}
                />
                <TextField
                  className={classes.textField}
                  id="organization"
                  label="Organization"
                  variant="outlined"
                  autoComplete="off"
                  onChange={(event) => this.onCityChange(event.target.value)}
                  disabled={this.state.messageSending}
                />
                <TextField
                  className={classes.textField}
                  id="city"
                  label="City"
                  variant="outlined"
                  autoComplete="off"
                  onChange={(event) => this.onCityChange(event.target.value)}
                  disabled={this.state.messageSending}
                />
                <TextField
                  className={classes.textField}
                  id="message"
                  label={this.state.messageRequiredError ? "Reason for requesting access \u2014 Required" : "Reason for requesting access"}
                  variant="outlined"
                  autoComplete="off"
                  multiline rows={10}
                  error={this.state.messageRequiredError}
                  onBlur={this.validateMessage}
                  onChange={(event) => this.onMessageChange(event.target.value)}
                  disabled={this.state.messageSending}
                />
                <FormGroup className={classes.checkboxRow}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.emailAlertsChecked}
                        onChange={(event) => this.onEmailAlertsChange(event.target.checked)}
                        name="emailAlertsChecked"
                        color="primary"
                        disabled={this.state.messageSending}
                      />
                    }
                    label="I would like occasional emails alerting me of new datasets, visualizations and updates to Arup Data Portal"
                  />
                  <FormControlLabel
                    control={
                        <Checkbox
                        onClick={this.onTosCheck}
                        value={this.tos}
                        required={true}
                        />
                    }
                    label={
                      <div>
                          <span>I agree to the Arup Data </span>
                          <a target="_blank" rel="noopener noreferrer" href={tosPath}>
                            Portal Terms of Service
                          </a>
                      </div>
                    }/>
                </FormGroup>
                <div>
                  {this.state.tosRequiredError
                    ? <span style={{color:'#f44336'}}>You must accept the Terms of Service to proceed.</span>
                    : <span></span>
                  }
                </div>
                <div className={classes.buttonRow}>
                  <div className={classes.spacer}/>
                  <ReCAPTCHA
                    ref={e => (this.captcha = e)}
                    sitekey={reCaptchaSiteKey}
                    onChange={this.onReCaptchaCheck}
                  />
                </div>
                <div className={classes.buttonRow}>
                  <div className={classes.spacer}/>
                  {
                    this.state.messageSendError
                    &&
                    <Typography variant="body1" className={classes.errorMessage}>
                      {this.state.messageSendError}
                    </Typography>
                  }
                  <Button
                    className={classes.sendButton}
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={!this.state.reCaptchaToken || this.state.messageSending}
                    onClick={this.sendMessage}
                  >
                    {this.state.messageSending
                      ? <CircularProgress size={20} thickness={6}/>
                      : "Send"
                    }
                  </Button>
                </div>
              </form>
            </div>
          }
        </Container>
      </div>

    );
  }
}

export default withStyles(styles)(RequestAccessPage);
