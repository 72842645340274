import React, { Component } from 'react';
import SwaggerUI from 'swagger-ui';
import PropTypes from 'prop-types';
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { AccordionSummary, Container, Typography } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ReactMarkdown from 'react-markdown';
import { ExpandLess } from "@material-ui/icons";
import PageHeader from "../PageHeader";
import UsageInstructions from "./UsageInstructions";

const styles = (theme: Theme) => ({
  datasetHeader: {
    marginTop: 24,
    fontWeight: 'bold'
  },
  accordionContainer: {
    marginTop: 36,
    marginBottom: 36
  },
  accordionDetails: {
    display: 'block'
  },
  apiContent: {
    padding: '12px 12px 1px 12px',
    background: '#FAFAFA'
  },
  apiHeader: {
    padding: '0 0 12px 4px'
  }
});

class DatasetPage extends Component {
  static propTypes = {
    datasetId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    swaggerDocLink: PropTypes.string.isRequired,
    documentationLink: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      markdown: ''
    }
  }

  fetchFile = async () => {
    let file = await fetch(this.props.documentationLink)
    return await file.text()
  }

  componentDidMount() {
    SwaggerUI({
      domNode: document.getElementById(this.props.datasetId),
      url: this.props.swaggerDocLink,
    })
    this.fetchFile().then(markdown => {
      this.setState({ markdown: markdown });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <PageHeader displayValue={`Dataset - ${this.props.name}`} showReturnToDatasets={true}/>
        <Container>
          <div className={classes.accordionContainer}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandLess/>}
                aria-controls="about-dataset-content"
              >
                <Typography variant="h6">About this dataset</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <ReactMarkdown children={this.state.markdown}/>
              </AccordionDetails>
            </Accordion>
            <UsageInstructions/>
          </div>

          <div className={classes.apiContent}>
            <Typography variant="h6" className={classes.apiHeader}>API</Typography>
            <div id={this.props.datasetId}/>
          </div>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(DatasetPage);
