import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { Link } from "react-router-dom";
import { datasetsPath } from "../../data/paths";

const styles = (theme: Theme) => ({
  datasetCard: {
    margin: '36px 0'
  },
  datasetHeader: {
    fontWeight: 'bold'
  },
});

class DatasetCard extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    datasetId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.datasetCard}>
        <CardContent>
          <Typography gutterBottom variant="h5" className={classes.datasetHeader}>
            {this.props.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {this.props.description}
          </Typography>
        </CardContent>

        <CardActions>
          <Button size="small" color="primary" component={Link} to={`${datasetsPath}/${this.props.datasetId}`}>
            Explore Data
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(DatasetCard);
