import React, { Component } from 'react';
import { Theme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import PropTypes from "prop-types";
import { Button, Container, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { datasetsPath } from "../data/paths";

const styles = (theme: Theme) => {
  return {
    pageHeader: {
      height: 64,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.light
    },
    flexRow: {
      display: 'flex',
      alignItems: 'center',
    },
    pageHeaderDisplayValue: {
      color: 'white',
      fontWeight: 500
    },
    spacer: {
      flexGrow: 1,
    }
  }
};

class PageHeader extends Component {
  static propTypes = {
    displayValue: PropTypes.string.isRequired,
    showReturnToDatasets: PropTypes.bool,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes, displayValue, showReturnToDatasets } = this.props;

    return (
      <div className={classes.pageHeader}>
        <Container className={classes.flexRow}>
          <Typography className={classes.pageHeaderDisplayValue} variant="h4">{displayValue}</Typography>
          <div className={classes.spacer}/>
          {
            showReturnToDatasets && <Button
              size="small"
              startIcon={<ArrowBack/>}
              component={Link}
              to={datasetsPath}
            >Return to datasets</Button>
          }
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(PageHeader);
