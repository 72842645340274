import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { Container, Typography } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandLess } from "@material-ui/icons";
import PageHeader from "../PageHeader";
import PowerBIPath from '../../markdowns/powerbi_faq.md';
import DataStudioPath from '../../markdowns/datastudio_faq.md';
import ExcelPath from '../../markdowns/excel_faq.md';
import ReactMarkdown from 'react-markdown';

const styles = (theme: Theme) => ({
  sectionHeader: {},
  question: {
    fontWeight: 500
  },
  questions: {
    marginTop: 24
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  image: {
    height: 'auto',
    width: '800px',
    maxWidth: '100%',
    border: '2px solid gray',
    marginTop: 18
  },
  markdown: {
    display: 'block'
  },
  heading: {
    display: 'block',
    marginBlockStart: '1.33em',
    marginBlockEnd: '1.33em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    fontWeight: 'bold',
  }
});

class FaqPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  
  constructor(props) {
    super(props)
    this.state = {
      powerBI: '',
      dataStudio: '',
      excel: ''
    }

  }

  
  componentDidMount() {
    fetch(PowerBIPath).then((response) => response.text()).then((text) => {
      this.setState({ powerBI: text })
    });
    fetch(DataStudioPath).then((response) => response.text()).then((text) => {
      this.setState({ dataStudio: text })
    });
    fetch(ExcelPath).then((response) => response.text()).then((text) => {
      this.setState({ excel: text })
    })
  }

  render() {
    const { classes } = this.props;

    const renderers = {

      //This custom renderer changes how images are rendered
      //we use it to constrain the max width of an image to its container

      image: ({
          alt,
          src,
          title,
        }: {
          alt?: string;
          src?: string;
          title?: string;
        }) => (
          <img 
              alt={alt} 
              src={src} 
              title={title} 
              style={{     
              height: 'auto',
              width: '600px',
              maxWidth: '100%',
              border: '2px solid gray',
              marginTop: 18}}  
          />
      ),
    };

    return (
      <div>
        <PageHeader displayValue="FAQ"/>
        <Container className={classes.container}>
          <div className={classes.questions}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandLess/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.question} variant="h6">
                  How do I obtain access to the Arup Data Portal datasets?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                In progress...
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandLess/>}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.question} variant="h6">
                  How do I test the endpoints on the dataset pages?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.flexColumn}>
                  <Typography className={classes.heading} variant="body1">1. Click the green &quot;Authorize&quot; button to enter in a valid API key. Initally, the lock icon will appear open.</Typography>
                  <img src="/data_access_1.png" alt="dataAccess1" className={classes.image}/>
                  <Typography className={classes.heading} variant="body1">2. Enter a valid API key into the &quot;Value&quot; box and select the green &quot;Authorize&quot; button.</Typography>
                  <img src="/data_access_2.png" alt="dataAccess2" className={classes.image}/>
                  <Typography className={classes.heading} variant="body1">3. If the API key is valid, the lock icon will appear closed. Once this is achieved, click &quot;Get&quot; next to the endpoint you would like to test. Then click &quot;Try it out&quot;.</Typography>
                  <img src="/data_access_3.png" alt="dataAccess3" className={classes.image}/>
                  <Typography className={classes.heading} variant="body1">4. Add required inputs and then select &quot;Execute&quot;. If the endpoint is working, the request will return a status code of 200.</Typography>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandLess/>}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography className={classes.question} variant="h6">
                  How do I modify the documentation for a dataset?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.flexColumn}>
                  <Typography className={classes.heading} variant="body1">1. To modify documentation for a dataset, you must first get access to the AMR Digital Asset Bank Git repository.</Typography>
                  <Typography className={classes.heading} variant="body1">2. Within the repository, there are folder names for each dataset in the portal. Click on the folder corresponding to the dataset whose documentation you would like to edit.</Typography>
                  <img src="/documentation_1.PNG" alt="documentation1" className={classes.image}/>
                  <Typography className={classes.heading} variant="body1">3. Within the &quot;swagger&quot; folder, there will be a README markdown file containing current documentation. Edit the README and commit changes to update documentation.</Typography>
                  <img src="/documentation_2.PNG" alt="documentation2" className={classes.image}/>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandLess/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.question} variant="h6">
                  What should I include in documentation for a new dataset?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <ol>
                    <li><b>Dataset Basics</b> 
                      <ul>
                        <li>Title of dataset</li>
                        <li>Date dataset was created</li>
                        <li>Dates the included data pertains to, rate of data capture, average delay of latest data if applicable</li>
                        <li>Data format (i.e. tabular, media, KML, etc.)</li>
                        <li>Overview of data included in the dataset</li>
                        <li>Owner/representative primary source and contact information</li>
                      </ul>
                    </li>
                    <li><b>Linked Datasets</b>
                      <ul>
                        <li>Links to specific datasets joined by the API</li>
                        <li>Short descriptions of each included dataset</li>
                        <li>An Entity Relationship Diagram (ERD) of the linked datasets if applicable</li>
                      </ul>                     
                    </li>
                    <li><b>User Notes</b>
                      <ul>
                        <li>Additional information the user may need to know to use the data</li>
                      </ul>   
                    </li>
                  </ol>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandLess/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.question} variant="h6">
                How do I reference a dataset in Microsoft Power BI?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.markdown}>
                <ReactMarkdown
                  children={this.state.powerBI}
                  renderers={renderers}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandLess/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.question} variant="h6">
                How do I reference a dataset in Google Data Studio?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.markdown}>
                <ReactMarkdown
                  children={this.state.dataStudio}
                  renderers={renderers}
                  />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandLess/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.question} variant="h6">
                How do I reference a dataset in Microsoft Excel?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.markdown}>
                <ReactMarkdown
                children={this.state.excel}
                renderers={renderers}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(FaqPage);
