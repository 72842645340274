import React, { Component } from 'react';
import { Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { mainPageExposition1, mainPageExposition2 } from "../data/mainPageExposition";
import { Container } from "@material-ui/core";

const styles = (theme: Theme) => ({
  card: {
    minHeight: 600,
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url('/New York City Buildings_© Daniel Imade_Arup.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    padding: 64
  },
  text: {
    fontSize: '1.5rem',
    padding: 8,
    color: 'white',
    fontWeight: 500,
    textShadow: ''
      + '-1px -1px 0 #000,'
      + '1px -1px 0 #000,'
      + '-1px 1px 0 #000,'
      + '1px 1px 0 #000,'
      + '0 0 32px #000,'
      + '0 0 32px #000,'
      + '0 0 32px #000,'
      + '0 0 32px #000'
  }
});

class MainPageExposition extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props

    return (
      <Card className={classes.card}>
        <Container className={classes.textContainer}>
          <Typography className={classes.text}>
            {mainPageExposition1}
          </Typography>
          <br/>
          <Typography className={classes.text}>
            {mainPageExposition2}
          </Typography>
        </Container>
      </Card>
    );
  }
}

export default withStyles(styles)(MainPageExposition);

