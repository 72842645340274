import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import PageHeader from "../PageHeader";
import ReactMarkdown from 'react-markdown';
import MarkdownPath from '../../markdowns/free_vis_tools.md';
import { Container } from "@material-ui/core";

const styles = (theme: Theme) => ({
});

class VisualizationsPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      markdown: ''
    }
  }

  componentDidMount() {
    fetch(MarkdownPath).then((response) => response.text()).then((text) => {
      this.setState({ markdown: text })
    })
  }

  

  render() {

    const renderers = {
      //This custom renderer changes how images are rendered
      //we use it to constrain the max width of an image to its container
      image: ({
          alt,
          src,
          title,
      }: {
          alt?: string;
          src?: string;
          title?: string;
      }) => (
          <img 
              alt={alt} 
              src={src} 
              title={title} 
              style={{     
              height: 'auto',
              width: '800px',
              maxWidth: '100%',
              border: '2px solid gray',
              marginTop: 18}}  
          />
      ),
  };

    return (
      <div>
        <PageHeader displayValue="Visualizations"/>
        <Container>
          <ReactMarkdown
          children={this.state.markdown}
          renderers={renderers}
          />
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(VisualizationsPage);
