export const datasetDefinitions = [
  {
    id: 'nycBuildings',
    name: 'NYC Buildings',
    description: 'The NYC Buildings Data is an aggregation of information from the New York City Open Data Portal (NYC ODP).  \n' +
      'The purpose of this dataset is to provide a detailed view of building characteristics, including physical characteristics, building systems, performance, ownership and use.\n' +
      'Where appropriate, data from the NYC ODP has been extended with analytics and insight from Arup designers and consultants.',
    swaggerLink: `https://${process.env.REACT_APP_BACKEND_S3Bucket}.s3.amazonaws.com/arup/swagger/NYC_Building_Explorer/swagger.yaml`,
    documentationLink: `https://${process.env.REACT_APP_BACKEND_S3Bucket}.s3.amazonaws.com/arup/swagger/NYC_Building_Explorer/README.md`,
  },
  {
    id: 'nycLL97',
    name: 'NYC LL97',
    description: 'The purpose of this dataset is to provide a detailed view of energy consumption and carbon emissions in privately owned buildings over 25,000 ft2.\n' +
      'The consumption data is aggregated from the New York City Open Data Portal (NYC ODP). \n' +
      'The data from the NYC ODP has been extended with analytics and insight from Arup designers and consultants.',
    swaggerLink: `https://${process.env.REACT_APP_BACKEND_S3Bucket}.s3.amazonaws.com/arup/swagger/NYC_LL97/swagger.yaml`,
    documentationLink: `https://${process.env.REACT_APP_BACKEND_S3Bucket}.s3.amazonaws.com/arup/swagger/NYC_LL97/README.md`,
  },
  {
    id: 'nycMTATurnstileData',
    name: 'NYC MTA Turnstile Data',
    description: 'The purpose of this dataset is to provide a detailed view of subway station entry and exit counts in four-hour windows.\n' +
      'The data was retrieved from the MTA\'s website where they post open source turnstile records weekly. \n' +
      'The data from the MTA has been extended with analytics and insight from Arup designers and consultants.',
    swaggerLink: `https:/${process.env.REACT_APP_BACKEND_S3Bucket}.s3.amazonaws.com/arup/swagger/NYC_MTA_Turnstile_Data/swagger.yaml`,
    documentationLink: `https://${process.env.REACT_APP_BACKEND_S3Bucket}.s3.amazonaws.com/arup/swagger/NYC_MTA_Turnstile_Data/README.md`,
  }
]
