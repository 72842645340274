import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import PageHeader from "../PageHeader";

const styles = (theme: Theme) => ({
  sectionHeader: {},
  sectionTitle: {
    textDecoration: 'underline',
    fontWeight: 'bold'
  }
});

class TosPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <PageHeader displayValue="Terms of Service"/>
          <Container className={classes.container}>
          <br/>
          PLEASE READ CAREFULLY THESE TERMS AND CONDITIONS (“TERMS”) BEFORE DOWNLOADING, INSTALLING OR USING THE API AND CONTENT (“DATA PORTAL”) PROVIDED BY OVE ARUP & PARTNERS P.C. (“ARUP”). THIS IS A LEGAL AGREEMENT BETWEEN ARUP AND YOU WHICH GOVERNS YOUR ACCESS TO AND USE OF THE DATA PORTAL.
          <br/><br/>
          BY DOWNLOADING, INSTALLING OR USING THE DATA PORTAL YOU ACKNOWLEDGE AND AGREE THAT:
          <ol type="a">
            <li>your use of The Data Portal is solely at your own risk;</li>
            <li>The Data Portal is licensed, not sold to you and you may use The Data Portal only as set forth in these Terms;​</li>
            <li>you consent to the collection, use, sharing and transfer of information, including the transfer and processing of your information outside your home country; and</li>
            <li>as set forth in these Terms, The Data Portal is provided “as is”.​</li>
          </ol>
          <br/>
          IF YOU DO NOT AGREE TO BE BOUND BY ABOVE TERMS (AS DESCRIBED IN FURTHER DETAIL BELOW) YOU MAY NOT DOWNLOAD, INSTALL OR USE THE DATA PORTAL.
          <br/><br/>
          <span className={classes.sectionTitle}>Modification of the Terms:</span>  Arup reserves the right to modify and/or change any of the Terms at any time and without prior notice. By continuing to use The Data Portal after Arup has posted a modification of these Terms, you agree to be bound by the modified Terms. If the modified Terms are not acceptable to you, your only recourse is to discontinue the use of The Data Portal. 
          <br/><br/>
          <span className={classes.sectionTitle}>Limited License:</span> Conditioned upon your compliance with these Terms, Arup hereby grants to you a limited, revocable, non-exclusive, personal, non-sublicensable, non-transferable, non-assignable license access and use The Data Portal, its API, and its Content. 
          <br/><br/>
          In addition to the other terms and conditions in these Terms, except as expressly set forth herein, you agree not to do the following:
          <ol type="a">
            <li>Implement features or business practices, or use the API or Content in a way that may harm the professional reputation or relationships of Arup;</li>
            <li>Use the API or Content for any illegal purposes, or in any manner which would violate these Terms, or breach any laws or regulations regarding privacy or data protection, or violate the rights of third parties or expose Arup to legal liability;</li>
            <li>Sell, lease, or monetize the API or Content in any way;</li>
            <li>Use the API or Content for marketing purposes;​</li>
            <li>Make any statements or use any API or Content in a manner that expresses or implies that you or your use of the API or Content is sponsored or endorsed by Arup;</li>
            <li>Copy, adapt, reformat, reverse-engineer, disassemble, decompile, decipher, translate or otherwise modify the API or Content through automated or other means;​</li>
            <li>Use Content in any manner that facilitates bias or discriminatory practices; or​</li>
            <li>Attempt to re-identify any de-identified or anonymized data.​</li>
          </ol>
          <br/>
          Any use of the Content or API in automation or other tools may only be on written approval from Arup.​
          <br/><br/>
          <span className={classes.sectionTitle}>Consent to Use of Data:</span> You agree that Arup may collect and use technical data and related information, including but not limited to technical information about your device and use of The Data Portal, system and application software, and peripherals, which are gathered periodically to facilitate the provision of software updates, product support and other services. Arup may use this information, as long as it is in a form that does not personally identify you, to improve its products, services or technologies among other things at its sole discretion.​
          <br/><br/>
          Third party open source data may be provided via The Data Portal that may include personal information subject to public disclosure. You agree to use this data in compliance with any applicable data privacy laws.​
          <br/><br/>
          <span className={classes.sectionTitle}>Support and Updates:</span> We may provide you with support for our API in our sole discretion and we may stop providing support to you at any time without notice or liability to you. We are not obliged to provide any training, support or technical assistance for the Content or the API.​
          <br/><br/>
          We may modify or release subsequent versions of the API and require that you use those subsequent versions. You acknowledge that once Arup releases a subsequent version of an API, the prior version of such API may stop working at any time or may no longer work in the same manner. 
          <br/><br/>
          <span className={classes.sectionTitle}>Costs and Fees:</span> The API and Content are currently provided for free, but Arup reserves the right to charge for the API and Content in the future, subject to any restrictions imposed by direct data providers. Each party will bear its own costs and expenses in performing its obligations under these Terms. 
          <br/><br/>
          <span className={classes.sectionTitle}>Ownership:</span> We own all rights, title, and interest in and to the a) API, and all elements, components, and executables of the APIs; and b) original Content. Except to the limited extent expressly provided in these Terms, neither party grants, nor shall the other party acquire, any right, title or interest (including any implied license) in or to any property of the first party under these Terms. All rights not expressly granted in these Terms are withheld. 
          <br/><br/> 
          <span className={classes.sectionTitle}>No warranty:</span> The Data Portal is made available by Arup to the user on the basis that no representations or warranties express or implied are made regarding its performance, the accuracy or completeness of the model or the data and/or Content contained therein or the output.  No reliance shall be placed upon the output of The Data Portal. The user must make their own investigations and assessments as required to satisfy themselves as to the adequacy or otherwise of the information contained in The Data Portal and the user assumes full responsibility for any loss resulting from use (or misuse) of The Data Portal. User hereby indemnifies Arup in respect of any loss or damage incurred in its use of The Data Portal. 
          <br/><br/>
          <span className={classes.sectionTitle}>WARRANTY DISCLAIMER.</span> THE DATA PORTAL, AND ANY SERVICES PERFORMED OR PROVIDED BY THE DATA PORTAL, IS PROVIDED ON AN “AS IS” BASIS, WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ARUP DISCLAIMS ALL WARRANTIES, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, TITLE, QUALITY, AND NONINFRINGEMENT. ARUP EXPRESSLY DISCLAIMS ANY WARRANTIES OF ANY KIND WITH RESPECT TO THE ACCURACY, VALIDITY, OR COMPLETENESS OF ANY INFORMATION OR FEATURES AVAILABLE THROUGH THE SERVICE, OR THE QUALITY OR CONSISTENCY OF THE SERVICE.
          <br/><br/>
          <span className={classes.sectionTitle}>Limitation of Liability:</span> TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL ARUP BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE DATA PORTAL, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF ARUP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. In no event shall Arup’s total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars ($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.
          <br/><br/>
          <span className={classes.sectionTitle}>Term and Termination:</span> These Terms will remain in effect until terminated. These Terms, and your rights and licenses hereunder, will terminate immediately upon your breach of the Terms. You may terminate the Terms by uninstalling and discontinuing your use of The Data Portal. Arup may terminate support of The Data Portal and/or these Terms, or limit or terminate your access to The Data Portal at any time for any reason.
          <br/><br/>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(TosPage);
