import React, { Component } from "react";
import "./App.css"
import "../node_modules/swagger-ui/dist/swagger-ui.css"
import { Container, Divider } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { withStyles, ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import DatasetCard from "./components/DatasetsPage/DatasetCard";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { datasetDefinitions } from "./data/datasetDefinitions";
import Box from "@material-ui/core/Box";
import MainPageExposition from "./components/MainPageExposition";
import ContactUsPage from "./components/ContactUsPage/ContactUsPage";
import DataAccessPage from "./components/RequestAccessPage/RequestAccessPage";
import NavBar from "./components/NavBar";
import { contactUsPath, requestAccessPath, datasetsPath, homePath, faqPath, visualizationsPath, tosPath } from "./data/paths";
import DatasetsPage from "./components/DatasetsPage/DatasetListPage";
import FaqPage from "./components/FaqPage/FaqPage";
import VisualizationsPage from "./components/VisualizationsPage/VisualizationsPage";
import TosPage from "./components/TosPage/TosPage";
import { theme } from "./theme";

const styles = (theme: Theme) => ({
  app: {
    overflowY: "scroll",
    height: "100vh"
  }
});

class App extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Box className={classes.app}>
            <NavBar/>
            <Divider/>
            <Switch>
              {/* Home */}
              <Route exact path={homePath}>
                <MainPageExposition/>
                <Container>
                  {datasetDefinitions.map(definition => {
                    return <DatasetCard
                      key={definition.id}
                      datasetId={definition.id}
                      name={definition.name}
                      description={definition.description}
                    />
                  })}
                </Container>
              </Route>
              {/* Datasets */}
              <Route path={datasetsPath}>
                <DatasetsPage/>
              </Route>
              {/* Data Access */}
              <Route path={requestAccessPath}>
                <DataAccessPage/>
              </Route>
              {/* Visualizations */}
              <Route path={visualizationsPath}>
                <VisualizationsPage/>
              </Route>
              {/* FAQ */}
              <Route path={faqPath}>
                <FaqPage/>
              </Route>
              {/* Contact Us */}
              <Route path={contactUsPath}>
                <ContactUsPage/>
              </Route>
              {/* Terms of Service */}
              <Route path={tosPath}>
                <TosPage/>
              </Route>
              {/* Redirect all 404's to home */}
              <Redirect to={homePath}/>
            </Switch>
          </Box>
        </Router>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
