import React, { Component } from 'react';
import { Container, Toolbar, Typography } from "@material-ui/core";
import { Theme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { contactUsPath, requestAccessPath, datasetsPath, homePath, faqPath, visualizationsPath, tosPath } from "../data/paths";

const styles = (theme: Theme) => ({
  title: {
    textDecoration: 'none',
    color: '#01579B',
    fontWeight: 'bold'
  },
  toolbar: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  navButton: {
    margin: '0 6px'
  },
  arupLogo: {
    width: 64,
    padding: 12,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  spacer: {
    flexGrow: 1,
  }
});

const navMenuItems = [
  { pathname: homePath, displayName: 'Home' },
  { pathname: datasetsPath, displayName: 'Datasets' },
  { pathname: requestAccessPath, displayName: 'Request Access' },
  { pathname: visualizationsPath, displayName: 'Visualizations' },
  { pathname: contactUsPath, displayName: 'Contact Us' },
  { pathname: faqPath, displayName: 'FAQ' },
  { pathname: tosPath, displayName: 'Terms of Service' },
]

class NavBar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  isSelected = (fullPathname: string, targetBasePathname: string) => {
    if (targetBasePathname === homePath) {
      return "default"
    }
    const basePathname = `/${fullPathname.split('/')[1]}`
    return targetBasePathname === basePathname ? "primary" : "default"
  }

  render() {
    const { classes, location } = this.props;

    return (
      <Container>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h4" className={classes.title} component={Link} to="/">
            Arup Data Portal
          </Typography>
          <div className={classes.spacer}/>
          {navMenuItems.map(item => {
            return <Button
              key={item.pathname}
              className={classes.navButton}
              component={Link}
              to={item.pathname}
              color={this.isSelected(location.pathname, item.pathname)}
            >{item.displayName}</Button>
          })}
          <a target="_blank" href="https://arup.com" rel="noopener noreferrer">
            <img src="/arup_black.svg" alt="arup.com" className={classes.arupLogo}/>
          </a>
        </Toolbar>
      </Container>
    );
  }
}

export default withStyles(styles)(withRouter(NavBar));
