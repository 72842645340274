import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { AccordionSummary, Typography } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandLess } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { requestAccessPath } from "../../data/paths";

const styles = (theme: Theme) => ({
  accordionDetails: {
    display: 'block'
  },
  greenAuthorizeButton: {
    color: '#49cc90'  // TODO: Check that this Swagger Authorize button color won't change?
  }
});

class UsageInstructions extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandLess/>}
          aria-controls="how-to-use-api"
        >
          <Typography variant="h6">Authorization</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          To begin testing the endpoints below, you need to authorize yourself with an API key. If you do not have an
          API key, please request one via the <Link to={requestAccessPath} target="_blank" rel="noopener noreferrer">Request
          Access form.</Link>
          <br/>
          <br/>
          If you have an API key, press the green <b className={classes.greenAuthorizeButton}>Authorize</b> button below.
          <br/>
          <br/>
          In the window that appears, paste your API key in the <b>Value</b> input box, then press the <b
          className={classes.greenAuthorizeButton}>Authorize</b> button.
          <br/>
          <br/>
          You should now be able to make requests to and receive data from the endpoints below.
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles)(UsageInstructions);
