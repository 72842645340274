import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { contactUsExposition1, contactUsExposition2 } from "../../data/contactUsExposition";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha"
import { reCaptchaSiteKey } from "../../data/reCaptchaSiteKey";
import { emailRegex } from "../../data/validationRegexes";
import PageHeader from "../PageHeader";
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import { sendMailEndpoint } from "../../endpoints/endpoints";

const styles = (theme: Theme) => ({
  container: {
    marginBottom: 24
  },
  description: {
    marginTop: 24
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    marginTop: 24
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24
  },
  spacer: {
    flexGrow: 1,
  },
  sendButton: {
    height: '36px',
    width: '70px'
  },
  errorMessage: {
    color: 'red',
    marginRight: 24
  }
});

class ContactUsPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      reCaptchaToken: '',
      // validation errors
      nameRequiredError: false,
      emailRequiredError: false,
      emailInvalidError: false,
      messageRequiredError: false,
      // state of sending message
      messageSending: false,
      messageSent: false,
      messageSendError: ''
    }
  }

  captcha;

  canSendMessage = () => {
    return !this.state.nameRequiredError
      && !this.state.emailRequiredError
      && !this.state.emailInvalidError
      && !this.state.messageRequiredError
  }

  isValidEmail = (email: string) => {
    return emailRegex.test(email)
  }

  validateName = () => {
    if (!this.state.name) {
      this.setState({ nameRequiredError: true })
    }
  }

  validateEmail = () => {
    if (!this.state.email) {
      this.setState({ emailRequiredError: true })
    } else if (!this.isValidEmail(this.state.email)) {
      this.setState({ emailInvalidError: true })
    }
  }

  validateMessage = () => {
    if (!this.state.message) {
      this.setState({ messageRequiredError: true })
    }
  }

  /**
   * Record the reCAPTCHA token after the user checks the "I'm not a robot" checkbox.
   */
  onReCaptchaCheck = (reCaptchaToken: string) => {
    this.setState({ reCaptchaToken: reCaptchaToken });
  }

  /**
   * Record changes to name, email, and message.
   */
  onNameChange = (name: string) => {
    this.setState({
      name: name,
      nameRequiredError: false
    })
  }

  onEmailChange = (email: string) => {
    this.setState({
      email: email,
      emailRequiredError: false,
      emailInvalidError: false
    })
  }

  onMessageChange = (message: string) => {
    this.setState({
      message: message,
      messageRequiredError: false
    })
  }

  sendMessage = () => {
    Promise.all([
      this.validateName(),
      this.validateEmail(),
      this.validateMessage()
    ]).then(() => {
      if (this.canSendMessage()) {
        this.setState({
          messageSending: true,
          messageSent: false,
          messageSendError: ''
        })

        const payload = {
          subject: "general request",
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
          recaptcha_token: this.state.reCaptchaToken
        }

        axios.post(sendMailEndpoint, payload)
          .then(response => {
            this.setState({
              messageSending: false,
              messageSent: true,
              messageSendError: ''
            })
          })
          .catch(err => {
            this.setState({
              messageSending: false,
              messageSent: false,
              reCaptchaToken: ''
            })
            this.captcha.reset()
            if (err.response) {
              if (err.response.status === 401) {
                this.setState({
                  messageSendError: 'Your message could not be sent. Please try again.'
                })
              } else if (err.response.status === 400) {
                this.setState({
                  messageSendError: err.response.data['error_message'] || 'Bad request.'
                })
              } else if (err.response.status === 500) {
                this.setState({
                  messageSendError: 'Server error. Please contact an administrator.'
                })
              }
            } else if (err.request) {
              this.setState({
                messageSendError: 'Message could not be sent. Please try again.'
              })
            } else {
              this.setState({
                messageSendError: 'Unknown error.'
              })
            }
          })
      }
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <PageHeader displayValue="Contact Us"/>
        <Container className={classes.container}>
          {this.state.messageSent
            ?
            <div className={classes.description}>
              <Typography variant="body1">
                Your message has been sent. We will get back to you as soon as we can. Thank you!
              </Typography>
            </div>
            :
            <div>
              <div className={classes.description}>
                <Typography variant="body1">
                  {contactUsExposition1}
                </Typography>
                <br/>
                <Typography variant="body1">
                  {contactUsExposition2}
                </Typography>
              </div>
              <form id="contact-form" noValidate autoComplete="off" className={classes.form}>
                <TextField
                  className={classes.textField}
                  id="name"
                  label={this.state.nameRequiredError ? "Name \u2014 Required" : "Name"}
                  variant="outlined"
                  autoComplete="off"
                  error={this.state.nameRequiredError}
                  onBlur={this.validateName}
                  onChange={(event) => this.onNameChange(event.target.value)}
                  disabled={this.state.messageSending}
                />
                <TextField
                  className={classes.textField}
                  id="email"
                  label={this.state.emailRequiredError ? "Email \u2014 Required" : this.state.emailInvalidError ? "Email \u2014 Invalid Format" : "Email"}
                  variant="outlined"
                  autoComplete="off"
                  type="email"
                  error={this.state.emailRequiredError || this.state.emailInvalidError}
                  onBlur={this.validateEmail}
                  onChange={(event) => this.onEmailChange(event.target.value)}
                  disabled={this.state.messageSending}
                />
                <TextField
                  className={classes.textField}
                  id="message"
                  label={this.state.messageRequiredError ? "Message \u2014 Required" : "Message"}
                  variant="outlined"
                  autoComplete="off"
                  multiline rows={10}
                  error={this.state.messageRequiredError}
                  onBlur={this.validateMessage}
                  onChange={(event) => this.onMessageChange(event.target.value)}
                  disabled={this.state.messageSending}
                />
                <div className={classes.buttonRow}>
                  <div className={classes.spacer}/>
                  <ReCAPTCHA
                    ref={e => (this.captcha = e)}
                    sitekey={reCaptchaSiteKey}
                    onChange={this.onReCaptchaCheck}
                  />
                </div>
                <div className={classes.buttonRow}>
                  <div className={classes.spacer}/>
                  {
                    this.state.messageSendError
                    &&
                    <Typography variant="body1" className={classes.errorMessage}>
                      {this.state.messageSendError}
                    </Typography>
                  }
                  <Button
                    className={classes.sendButton}
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={!this.state.reCaptchaToken || this.state.messageSending}
                    onClick={this.sendMessage}
                  >
                    {this.state.messageSending
                      ? <CircularProgress size={20} thickness={6}/>
                      : "Send"
                    }
                  </Button>
                </div>
              </form>
            </div>
          }
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(ContactUsPage);
