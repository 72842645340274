import React, { Component } from 'react';
import { Container } from "@material-ui/core";
import { datasetDefinitions } from "../../data/datasetDefinitions";
import DatasetCard from "./DatasetCard";
import { Route, withRouter } from "react-router-dom";
import DatasetPage from "./DatasetPage";
import PageHeader from "../PageHeader";

class DatasetListPage extends Component {
  render() {
    const { match } = this.props;

    return (
      <div>
        <Route exact path={match.url}>
          <PageHeader displayValue="Datasets"/>
          <Container>
            {datasetDefinitions.map(definition => {
              return <DatasetCard
                key={definition.id}
                datasetId={definition.id}
                name={definition.name}
                description={definition.description}
              />
            })}
          </Container>
        </Route>
        {datasetDefinitions.map(definition => {
          return <Route key={definition.id} path={`${match.url}/${definition.id}`}>
              <DatasetPage
                datasetId={definition.id}
                name={definition.name}
                swaggerDocLink={definition.swaggerLink}
                documentationLink={definition.documentationLink}
              />
          </Route>
        })}
      </div>
    );
  }
}

export default withRouter(DatasetListPage);
